const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

export default {
    auth: {
        login: baseUrl + 'auth/login/',
        updatePassword: baseUrl + 'auth/set-password/',
        logout: baseUrl + 'auth/logout/',
        status: baseUrl + 'auth/status/'
    },
    gallery: {
        add: baseUrl + 'gallery/add/',
        list: baseUrl + 'gallery/list/',
        delete: baseUrl + 'gallery/delete/'
    },
    getquote: {
        add: baseUrl + 'getquote/add/',
        list: baseUrl + 'getquote/list/',
        delete: baseUrl + 'getquote/delete/'
    }
};
