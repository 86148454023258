import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');
const Dashboard = () => import(/* webpackChunkName: "home" */ '../views/TheDashboard');
// const Home = () => import(/* webpackChunkName: "home" */'../views/TheDashboard');
// const About = () => import(/* webpackChunkName: "home" */'../views/About');

export default {
    path: '/admin/',
    name: 'DashboardLayout',
    redirect: '/dashboard/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            name: 'Home',
            component: () => import(/* webpackChunkName: "home" */'../views/TheDashboard')
        },
        {
            path: '/dashboard/',
            name: 'Dashboard',
            component: Dashboard
        },
        {
            path: '/gallery/',
            name: 'Gallery',
            component: () => import(/* webpackChunkName: "home" */'../views/TheGallery')
        },
        {
            path: '/quotes/',
            name: 'Quotes',
            component: () => import(/* webpackChunkName: "home" */'../views/TheQuotes')
        },
        {
            path: '/update-password/',
            name: 'UpdatePassword',
            component: () => import('../views/auth/UpdatePassword')
        },
        {
            path: '/logout/',
            name: 'Logout',
            component: () => import('../views/auth/Logout')
        }

    ]
};
